<template>
  <div>
    <div class="newslist">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">最新资讯</div>
          <div class="tit2"></div>
        </div>
      </div>
      <ul>
        <li
          v-for="(item, index) in newslist"
          :key="index"
          @click="routerlink(item.articleId,index)"
        >
          <div class="newslist_info">
            <div class="infoimg">
              <img :src="item.articleCover" alt="" />
            </div>
            <div class="infocon">
              <div class="contop">
                <div class="toptit" v-html="item.articleTitle"></div>
                <div class="toptxt" v-html="item.articleContent">
                  
                </div>
              </div>

              <div class="conbot">
                <div class="infoleft">
                  <div class="newssee">
                    <img src="@/assets/images/01首页/浏览.png" alt="" />
                    <span>132</span>
                  </div>
                  <div class="newsnice">
                    <img src="@/assets/images/01首页/点赞.png" alt="" />
                    <span>86</span>
                  </div>
                </div>
                <div class="inforight">
                  <div class="newstime">
                    <img src="@/assets/images/01首页/发布日期.png" alt="" />
                    <span>2019-04-16</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import newsimg1 from "@/assets/images/03新闻动态/方彤：现实与虚拟场景“全”交互体验.png";
import newsimg2 from "@/assets/images/03新闻动态/梦宇3D 亮相国家“十二五”科技创新成就展.png";
import newsimg3 from "@/assets/images/03新闻动态/中影梦宇实时动作捕捉中心.png";
import newsimg4 from "@/assets/images/03新闻动态/总经理方彤到访合肥研究院先进制造所.png";
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      newslist: [
        {
          src: newsimg1,
          tit: "方彤：现实与虚拟场景“全”交互体验",
          con: "第三届全球传感器高峰论坛暨中国物联网应用峰会今天在合肥召开。以“聚集真知灼见，共谋产业发展”为主题，以“高峰论坛+展览展示+投资洽谈+参观对接”为形式，邀请政府主管部门、国内外知名传感器、物联网应用厂商、系统整机企业、科研机构",
        },
        {
          src: newsimg2,
          tit: "梦宇3D 亮相国家“十二五”科技创新成就展",
          con: "6月1日至6月7日，南京梦宇三维技术有限公司（简称梦宇3D）的VR交互体验项目作为国家“十二五”科技创新成就展上进行了展示",
        },
        {
          src: newsimg3,
          tit: "中影梦宇实时动作捕捉中心",
          con: "中影梦宇实时动作捕捉，前身为龙华电影数字制作有限公司运动捕捉工作室。从2001年开始就致力于动作捕捉项目的开发和制作，是国内最早进入动作捕捉行业的公司。现在作为最先进和成熟的动作捕捉棚活跃在北京的动作捕捉市场上，曾参与制作。。。",
        },
        {
          src: newsimg4,
          tit: "总经理方彤到访合肥研究院先进制造所",
          con: "应中国科学院合肥物质科学研究院先进制造技术研究所所长王容川的邀请，梦宇三维技术有限公司总经理、创始人方彤来到先进制造技术研究所交流考察访问，并作了题为《解读“工业4.0”——“中国制造2025”之路》的学术报告。",
        },
      ],

    };
  },
  created() {
    this.getcompany();
  },
  methods: {
    routerlink(id,index) {
      // console.log(id);
      // console.log(index);
      this.$router.push({ path: "/news/newsdetail", query: { id: id,index:index } });
    },
    async getcompany() {
      let params = {
        barId: 3,
      };
      const data = await getbannerdetail(params);
     
      this.newslist = data.data.children[0].portalArticleList;
      for (let i = 0; i < this.newslist.length; i++) {
        let text='http://36.152.65.166:10008';
        this.newslist[i].articleCover=text+this.newslist[i].articleCover
        
      }
      
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.product_title {
  width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 13px;
    }
    .tit2 {
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
}
.newslist {
  height: 1408px;
  ul {
    width: 1100px;

    margin: 0 auto;
    margin-top: 20px;
    li {
      cursor: pointer;
      margin-bottom: 30px;
      .newslist_info {
        display: flex;
        .infoimg {
          width: 360px;
          height: 260px;
          box-sizing: border-box;
          border: 1px solid #c1c3cb;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid #ffffff;
          }
        }
        .infocon {
          width: 740px;
          height: 260px;
          background-color: #e8eaf2;

          .contop {
            padding: 0 33px;
            height: 200px;
            display: flex;
            flex-direction: column;

            justify-content: space-evenly;

            .toptit {
              font-size: 20px;
              font-weight: bold;
            }
            .toptxt {
              margin-top: -20px;
              font-size: 16px;
              width: 95%;
              height: auto;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            }
          }
          .conbot {
            display: flex;
            justify-content: space-between;
            height: 60px;
            width: 100%;
            border-top: 2px solid #c1c3cb;
            img {
              margin-right: 11px;
            }
            .infoleft {
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: space-around;
              div {
                display: flex;
                align-items: center;
                span {
                  font-size: 20px;
                }
              }
            }
            .inforight {
              width: 30%;
              display: flex;
              align-items: center;
              div {
                display: flex;
                align-items: center;
                span {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>